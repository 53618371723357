@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    font-family: LexendDeca !important;
    background-color: #6156A5;
}

@font-face {
    font-family: LexendDeca;
    src: url('../LexendDeca.ttf');
}
